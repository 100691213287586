import React from "react";
import ReactDOM from "react-dom/client"; // Import ReactDOM for rendering the app
import "./index.css"; // Import the CSS file for global styles
import App from "./App"; // Import the main App component
import reportWebVitals from "./reportWebVitals"; // Import for measuring performance
import { GoogleOAuthProvider } from "@react-oauth/google"; // Import Google OAuth provider
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter for routing

// Create a root element for rendering the React app
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Render the React app
root.render(
  // Provide Google OAuth context to the app
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE || ""}>
    {/* StrictMode is a tool for highlighting potential problems in an application */}
    <React.StrictMode>
      {/* BrowserRouter provides routing capabilities */}
      <BrowserRouter>
        {/* Main App component */}
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// Measure performance in the app
// Pass a function to log results (e.g., reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
