import React, { useState } from "react";
import {
  autocompleteAddress,
  DataContextType,
  NutritionFood,
  ProductsEachStore,
  StoreIDsData,
} from "./types";

// Default context value with dummy functions and initial values
const defaultContextValue: DataContextType = {
  isFetch: false,
  setIsFetch: () => {},
  isSuccessful: false,
  setIsSuccessful: () => {},
  address: undefined,
  setAddress: undefined,
};

// Create a context with the default value
const DataContext = React.createContext<DataContextType>(defaultContextValue);

interface DataProviderProps {
  children: JSX.Element;
}

// DataProvider component to provide context values
const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  // State variables for various context values

  const [address, setAddress] = useState<autocompleteAddress | string>(
    "Address"
  );

  const [food, setFood] = useState<Record<string, string>>({});
  const [autoComplete, setAutoComplete] = useState<NutritionFood[]>([]);

  const [isFetch, setIsFetch] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isFetchLowestPricedBasket, setIsFetchLowestPricedBasket] =
    useState<boolean>(false);
  // const [groceryOptions, setGroceryOptions] =
  //   useState<ProductsEachStore | null>(null);
  const [storeIDs, setStoreIDs] = useState<StoreIDsData | null>(null);
  const [radius, setRadius] = useState<string>("3");

  const [isFetchStoresIDs, setIsFetchStoresIDs] = useState<boolean>(false);

  return (
    <DataContext.Provider
      value={{
        address,
        setAddress,
        radius,
        setRadius,
        food,
        setFood,
        autoComplete,
        setAutoComplete,
        // groceryOptions,
        // setGroceryOptions,
        storeIDs,
        setStoreIDs,

        isFetch,
        setIsFetch,
        isSuccessful,
        setIsSuccessful,
        isFetchLowestPricedBasket,
        setIsFetchLowestPricedBasket,

        isFetchStoresIDs,
        setIsFetchStoresIDs,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
