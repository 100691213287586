import React from "react";
import "./App.css"; // Import the CSS file for styling
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"; // Import react-query components
import { DataProvider } from "./backend/DataContext"; // Import the DataProvider for context
import { Provider, defaultTheme } from "@adobe/react-spectrum"; // Import Adobe React Spectrum components
import { ToastContainer } from "@react-spectrum/toast"; // Import ToastContainer for notifications
import Navigation from "./backend/Navigation"; // Import the Navigation component

// Create a new QueryClient instance for react-query
const queryClient = new QueryClient();

// Define the main App component
function App() {
  return (
    <div className="App">
      {/* Wrap the application with DataProvider to provide context */}
      <DataProvider>
        {/* Provide the QueryClient instance to the application */}
        <QueryClientProvider client={queryClient}>
          {/* Adobe React Spectrum Provider for theming */}
          <Provider theme={defaultTheme}>
            {/* Navigation component for routing */}
            <Navigation />
            {/* ToastContainer for displaying notifications */}
            <ToastContainer />
          </Provider>
        </QueryClientProvider>
      </DataProvider>
    </div>
  );
}

// Export the App component as the default export
export default App;
