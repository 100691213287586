import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ProgressCircle } from "@adobe/react-spectrum";

// Lazy load components with a delay for better performance
const Home = lazy(() => import("../screens/HomeScreen/Home"));
const InitialScreen = lazy(
  () => import("../screens/InitialScreens/InitialScreen")
);
const GroceriesOption = lazy(
  () => import("../screens/GroceriesScreens/GroceriesOption")
);
const CheckList = lazy(() => import("../screens/GroceriesScreens/CheckList"));

// Navigation component to handle routing
const Navigation: React.FC = () => {
  return (
    <nav role="navigation">
      {/* Suspense component to show a fallback loader while lazy-loaded components are being fetched */}
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressCircle size="L" aria-label="Loading…" isIndeterminate />
          </div>
        }
      >
        {/* Define routes for the application */}
        <Routes>
          <Route path="/" element={<InitialScreen />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Home/Grocery" element={<GroceriesOption />} />
          <Route path="/Home/Grocery/List" element={<CheckList />} />
        </Routes>
      </Suspense>
    </nav>
  );
};

export default Navigation;
